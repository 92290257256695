.contactus-page {
  background: linear-gradient(135deg, #e3f2fd 0%, #ffebee 100%);
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
}

.contactus-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.contactus-form-section h1 {
  font-size: 42px;
  color: #333;
  background: linear-gradient(to right, #42a5f5, #ef5350);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.contactus-form-section p {
  color: #555;
  margin-bottom: 30px;
  font-size: 18px;
}

.contact-form {
  display: grid;
  gap: 20px;
  animation: fadeIn 1s ease-in-out;
}

.input-group label {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.input-group input,
.input-group textarea {
  padding: 15px;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 100%;
  background: linear-gradient(to right, #ffffff, #f1f8e9);
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.input-group input:hover,
.input-group textarea:hover {
  background: linear-gradient(to right, #f1f8e9, #ffe0b2);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.15);
}

.input-group input:focus,
.input-group textarea:focus {
  border-color: #42a5f5;
  outline: none;
  background: linear-gradient(to right, #e3f2fd, #c8e6c9);
  box-shadow: 0 4px 10px rgba(66, 165, 245, 0.3);
}

textarea {
  resize: vertical;
  min-height: 150px;
}

.submit-btn {
  padding: 15px;
  background: linear-gradient(to right, #42a5f5, #ef5350);
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 8px 20px rgba(66, 165, 245, 0.5);
}

.submit-btn:hover {
  background: linear-gradient(to right, #ef5350, #42a5f5);
  box-shadow: 0 10px 25px rgba(66, 165, 245, 0.6);
  transform: translateY(-2px);
}

.contactus-info-section h3 {
  font-size: 26px;
  color: #333;
  background: linear-gradient(to right, #42a5f5, #ef5350);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.social-links {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.social-icon {
  font-size: 24px;
  color: #42a5f5;
  transition: color 0.3s, transform 0.3s;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.social-icon:hover {
  color: #ef5350;
  transform: scale(1.2);
  text-shadow: 0 5px 10px rgba(66, 165, 245, 0.3);
}

.map-container iframe {
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.company-address p,
.contact-info p {
  font-size: 16px;
  color: #333;
}

.contact-info p {
  font-weight: bold;
  color: #42a5f5;
}

@media (max-width: 768px) {
  .contactus-container {
    grid-template-columns: 1fr;
  }

  .submit-btn {
    width: 100%;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
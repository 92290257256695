/* src/App.css */
  /* .App {
  padding-top: 70px; Adjusted for Header
} */

main {
  margin-top: 20px;
  padding-bottom: 20px;
}

/* Make main content responsive */
@media (max-width: 768px) {
  main {
    padding: 10px;
  }
}

.footer {
  /* background: #333;  */
  background: linear-gradient(to right, #2c3e50, #4ca1af);
  color: white;
  padding: 2em 1em; /* Increased padding for better spacing */
  text-align: center;
}

.footer-content {
  display: flex; /* Flexbox layout */
  justify-content: space-around; /* Space between sections */
  align-items: flex-start; /* Align items to the start */
  max-width: 1200px; /* Max width for larger screens */
  margin: 0 auto; /* Center the container */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.footer-section {
  flex: 1; /* Each section takes equal space */
  min-width: 200px; /* Minimum width for sections */
  margin: 0 1em; /* Margin between sections */
}

.footer-section h3 {
  margin-bottom: 1em; /* Space below section headings */
}

.footer-section ul {
  list-style: none; /* Remove bullet points */
  padding: 0;
}

.footer-section li {
  margin: 5px 0; /* Space between links */
}

.footer-section a {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.footer-section a:hover {
  color: #ff6347; /* Highlight color on hover */
}

.social-icons {
  display: flex; /* Horizontal layout for social icons */
  justify-content: center; /* Center align social icons */
  margin-top: 10px; /* Space above icons */
}

.social-icon {
  margin: 0 10px; /* Space between icons */
  color: white;
  font-size: 24px; /* Size of icons */
  transition: color 0.3s, transform 0.3s; /* Transform for scaling effect */
}

.social-icon:hover {
  color: #ff6347; /* Highlight color on hover */
  transform: scale(1.2); /* Scale effect on hover */
}

.footer-copyright {
  margin-top: 1em; /* Space above copyright */
  font-size: 14px; /* Smaller font size for copyright */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center align sections */
    padding: 1.5em 0; /* Adjust padding for smaller screens */
  }

  .footer-section {
    margin: 1em 0; /* Space between stacked sections */
    text-align: center; /* Center text in sections */
  }
}

/* src/styles/AboutUs.css */

.aboutus-page {
  background: linear-gradient(135deg, #f0f4f8, #e0e6ed); /* Gradient background */
  color: #333;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  animation: fadeIn 1s ease-in-out; /* Fade-in animation for the whole page */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Header */
.aboutus-header {
  text-align: center;
  padding: 40px 20px;
  background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradient background */
  color: white;
  border-radius: 8px;
  margin-bottom: 40px;
  animation: slideIn 0.8s ease-out; /* Slide-in animation */
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.aboutus-header h1 {
  font-size: 48px;
}

.aboutus-header p {
  font-size: 20px;
}

/* Content Section */
.content-section {
  display: flex;
  flex-direction: row; /* Content on right and image on left */
  align-items: center;
  margin: 40px 0;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s; /* Add transition for hover effect */
}

.content-section:hover {
  transform: translateY(-5px); /* Slightly lift on hover */
}

.content {
  flex: 1;
  padding: 20px;
}

.content p {
  font-size: 18px;
  line-height: 1.5;
}

/* Image styles */
.image-container {
  flex: 1; /* Make image section flexible */
}

.image-container img {
  width: 100%; /* Make image responsive */
  border-radius: 8px;
  transition: transform 0.3s; /* Add transition for image hover effect */
}

.image-container img:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Values Section */
.values-section {
  margin: 40px 0;
}

.values-section h2 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 50px;
  position: relative;
}

.values-section h2::after {
  content: '';
  display: block;
  width: 50%;
  height: 4px;
  background: linear-gradient(135deg, #6a11cb, #2575fc); /* Gradient underline */
  margin: 10px auto 0;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive grid */
  gap: 15px;
  justify-items: center;
  margin-bottom: 50px;
}

.value-item {
  background: #6a11cb;
  color: white;
  padding: 15px;
  border-radius: 20px;
  transition: transform 0.3s, background-color 0.3s; /* Added transition for background color */
  text-align: center; /* Center align text */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.value-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  background: #2575fc; /* Change background color on hover */
}

/* Team Section */
.team-section {
  text-align: center;
  margin: 40px 0;
}

.team-section h2 {
  font-size: 36px;
}

.team-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping for small screens */
}

.team-member {
  margin: 20px;
  text-align: center;
  transition: transform 0.3s; /* Add transition for hover effect */
}

.team-member:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.team-member img {
  border-radius: 50%;
  margin-bottom: 10px;
  width: 150px; /* Set a default width for the team images */
  height: 150px; /* Set a default height for the team images */
  object-fit: cover; /* Maintain aspect ratio */
}

/* Social Links */


.social-links-about {

  /* display: flex; */
  gap: 20px;
  margin-top: 10px; /* Space above the social links */
}

.social-links-about a {
  margin: 0 10px; /* Space between links */
  text-decoration: none; /* Remove underline */
  color: #2575fc; /* Default color for the links */
  transition: color 0.3s ease; /* Smooth transition for color change */
  font-size: 24px; /* Size of the icons */
}

.social-links-about a:hover {
  color: #6a11cb; /* Change color on hover */
}

/* Footer */
.about-footer {
  text-align: center;
  margin-top: 40px;
  font-size: 18px;
}

/* Responsive styling */
@media (max-width: 1024px) {
  .aboutus-header h1 {
    font-size: 36px; /* Reduce header size on medium screens */
  }

  .aboutus-header p {
    font-size: 18px; /* Reduce paragraph size on medium screens */
  }

  .values-section h2, .team-section h2 {
    font-size: 30px; /* Reduce section title size on medium screens */
  }
}

@media (max-width: 768px) {
  .content-section {
    flex-direction: column; /* Stack content and image */
    align-items: center; /* Center align items */
  }

  .content {
    padding: 10px;
    text-align: center; /* Center text for mobile */
  }

  .image-container {
    width: 100%; /* Make image container responsive */
  }

  .image-container img {
    width: 100%; /* Make image responsive on small screens */
    height: auto; /* Maintain aspect ratio */
    max-width: 700px; /* Cap maximum width */
  }

  .team-member img {
    width: 120px; /* Adjust size for team images on smaller screens */
    height: 120px;
  }
}

@media (max-width: 480px) {
  .aboutus-header h1 {
    font-size: 28px; /* Further reduce header size on small screens */
  }

  .aboutus-header p {
    font-size: 16px; /* Further reduce paragraph size on small screens */
  }

  .values-section h2, .team-section h2 {
    font-size: 24px; /* Further reduce section title size on small screens */
  }

  .value-item {
    font-size: 14px; /* Reduce font size of values items on small screens */
  }

  .social-links-about a {
    font-size: 20px; /* Reduce icon size on small screens */
  }
}

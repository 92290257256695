/* General Page Styles */
.mission-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background: #009688;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.hero-content {
  flex: 1;
  text-align: left;
  margin-left: 30px; /* Added margin to create space between image and content */
}

.hero-content h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 18px;
  line-height: 1.5;
}

.hero-image {
  flex: 1;
  text-align: center;
  order: -1; /* This moves the image to the left side */
  margin-right: 30px; /* Added margin to create space between image and content */
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Mission Points Section */
.mission-points {
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
  background: #f9f9f9;
  gap: 20px;
}

.mission-point {
  text-align: center;
  flex: 1;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.mission-point:hover {
  transform: translateY(-10px);
}

.icon {
  font-size: 50px;
  margin-bottom: 15px;
  color: #009688;
}

.mission-point h3 {
  margin: 10px 0;
  font-size: 24px;
}

.mission-point p {
  font-size: 16px;
  color: #555;
}

/* Timeline Section */
.mission-timeline {
  position: relative;
  padding: 40px 20px;
  background: #ffffff;
  border-top: 2px solid #009688;
}

.timeline-line {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 6px;
  background: linear-gradient(to bottom, #00bfae, #009688);
  z-index: 0;
  transform: translateX(-50%);
  border-radius: 5px;
}

.timeline-event {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.5s ease-in-out;
}

.timeline-event.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.event-dot {
  width: 20px;
  height: 20px;
  background-color: #009688;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.event-content {
  margin-left: 60px;
  max-width: 500px;
  padding: 20px;
  background: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.timeline-event h4 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #009688;
}

.timeline-event p {
  font-size: 16px;
  color: #333;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .mission-points {
    flex-direction: column;
    align-items: center;
  }

  .mission-point {
    margin-bottom: 30px;
  }

  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-image {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: auto;
  }

  .hero-section h1 {
    font-size: 30px;
  }

  .mission-points {
    flex-direction: column;
  }

  .mission-point {
    margin-bottom: 30px;
    width: 80%;
  }

  .timeline-event {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .event-content {
    margin-left: 0;
  }
}

/* For small devices */
@media (max-width: 480px) {
  .mission-point h3 {
    font-size: 20px;
  }

  .mission-point p {
    font-size: 14px;
  }

  .timeline-event h4 {
    font-size: 20px;
  }

  .timeline-event p {
    font-size: 14px;
  }
}

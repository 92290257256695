/* src/styles/Home.css */

/* Basic setup for the home page */
.home-page {
  position: relative;
  width: 100%;
  height: auto; /* Allow height to adjust based on content */
}

/* Fullscreen background slider */
.slider {
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
}

/* Overlay content */
.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
  color: white;
  font-family: 'Arial', sans-serif;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.overlay-content h1 {
  font-size: 48px; /* Increased font size */
  margin-bottom: 20px; /* Increased margin between h1 and p */
}

.overlay-content p {
  font-size: 28px; /* Increased font size only for the paragraph */
}

/* Pagination dots container on top of the slider */
.pagination-dots {
  position: absolute;
  bottom: 80px; /* Adjust this value to move the dots higher or lower */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
  z-index: 20; /* Higher z-index to stay on top of the slider */
}

/* Styling for dots */
.dot {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.dot.active {
  transform: scale(1.5);
  background: #ff5e62; /* Highlight active dot */
}

/* Info Sections */
/* .info-sections { */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* margin: 40px 0; Space above and below */
  /* padding: 0 20px; Padding for responsive layout */
/* } */

/* .info-column { */
  /* flex: 1; Each column takes equal space */
  /* min-width: 200px; Minimum width for columns */
  /* padding: 20px; Padding for each column */
  /* text-align: center; Center align text */
/* } */

/* .line { */
  /* width: 2px; Line width */
  /* height: 100px; Line height */
  /* background-color: #ccc; Line color */
  /* margin: 0 20px; Margin between columns */
/* } */

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .overlay-content h1 {
    font-size: 36px; /* Smaller font size for mobile */
  }

  .overlay-content p {
    font-size: 18px; /* Smaller paragraph size for mobile */
  }

  /* .info-sections { */
    /* flex-direction: column; Stack columns vertically */
    /* align-items: center; Center align */
  /* } */

  /* .line { */
    /* display: none; Hide line on small screens */
  /* } */
}

/* General Header Styling */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to right, #4a4266, #946852); /* Saffron to a deeper orange shade */
  padding: 20px 40px; /* Increased padding to make header bigger */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 50px; /* Increased height of the header */
}

/* Logo Styling */
.header .logo {
  font-size: 28px; /* Increased font size for the logo */
  font-weight: bold;
  color: white;
  text-decoration: none;
  flex-grow: 1; /* Ensures logo stays on the left */
  margin-left: -20px; /* Moves logo a bit to the left */
}

/* Navigation Links */
.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 20px; /* Increased gap between nav items */
}

.nav-links li {
  padding: 5px 10px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 20px; /* Increased font size for the navigation links */
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #ff6347;
}

/* Hamburger Menu (Visible only on mobile) */
.hamburger-menu {
  display: none; /* Hidden by default */
  cursor: pointer;
  font-size: 35px; /* Increased size for mobile hamburger icon */
  color: white;
  z-index: 1001;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Mobile Menu */
.nav-links.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: none; /* Initially hidden */
  gap: 25px;
  padding-top: 80px;
}

.nav-links.mobile-menu.active {
  display: flex; /* Show menu when active */
}

.nav-links.mobile-menu a {
  font-size: 24px;
  color: white;
  text-align: center;
}

/* Close Icon */
.close-menu {
  display: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  cursor: pointer;
  color: white;
  z-index: 1002;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide navigation links on mobile */
  }

  .hamburger-menu {
    display: block; /* Show hamburger menu only on mobile */
  }

  .close-menu {
    display: block; /* Show close icon only on mobile */
  }
}

/* Add margin-top to body content to prevent overlap */
body {
  margin-top: 100px; /* Increased space for the bigger header */
}

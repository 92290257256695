/* src/styles/Faq.css */

.faq-page {
  background-color: #f7f7f7; /* Soft background color */
  color: #333;
  padding: 60px 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.faq-container {
  max-width: 900px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 40px;
  position: relative; /* Position relative for bubble effect */
  overflow: hidden; /* Hide overflow for the bubble animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.faq-container h1 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 20px;
  color: #2c3e50; /* Darker color for the title */
}

.faq-search {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.faq-search:focus {
  border-color: #2980b9; /* Highlight border on focus */
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.faq-card {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s; /* Transition for box-shadow */
  cursor: pointer;
  padding: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #2980b9; /* Unique color for questions */
  color: white;
  border-radius: 10px;
  transition: background-color 0.3s; /* Smooth transition */
  position: relative; /* Position relative for the icon */
}

.faq-question:hover {
  background-color: #1f618d; /* Darker shade on hover */
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  padding: 0 15px; /* Add padding for answer */
  transition: max-height 0.3s ease, padding 0.3s ease; /* Smooth transition */
  background-color: #ecf0f1; /* Light background for answers */
}

.faq-answer.show {
  max-height: 200px; /* Adjust as necessary */
  padding: 15px; /* Padding for answer */
}

.icon {
  transition: transform 0.3s;
}

.rotate {
  transform: rotate(180deg);
}

/* Bubble animation for the FAQ container */
.bubble-animation {
  animation: bubble 1.2s ease-in-out forwards;
}

@keyframes bubble {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    background-color: transparent;
  }
}

@media (max-width: 768px) {
  .faq-container {
    padding: 20px;
  }

  .faq-container h1 {
    font-size: 28px;
  }

  .faq-search {
    font-size: 14px;
  }

  .faq-question {
    font-size: 16px;
  }
}
